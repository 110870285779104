import { Link } from '../../components/Link';
import { getHrefWithSameQueryParams } from '../../utilities';
import { RoutePaths } from '../../RoutePaths';
import { Highlight } from '../../components/Highlight';
export const CSQuickCoachPage = () => (
  <>
    <section className="mt-8 px-4 lg:mt-16 lg:px-8">
      <div className="container mx-auto">
        <div className="max-w-medium mx-auto flex flex-col gap-8 lg:flex-row">
          <div className="shrink-0 grow-0 pt-2 lg:basis-[150px]">
            <Link
              event="website_csQuickCoach_back_click"
              to={getHrefWithSameQueryParams(RoutePaths.CASE_STUDIES)}
              className="text-p text-lime-600"
            >
              <i className="fa fa-arrow-left"></i> <span>All case studies</span>
            </Link>
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="text-h4 text-light-500">
              A game-changer for QuickCoach - 10x cost reduction, 3x performance
              boost!
            </h4>
            <h6 className="text-h6 text-light-500 font-light">
              "Working with zCloud was a game-changer for QuickCoach! Hosting
              costs reduced by 10x, app performance boosted by 3x. Users are
              thrilled!."
            </h6>
            <div className="flex items-center gap-2">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-quickcoach-photo.png"
                alt="Reynaldo Reyna"
              />
              <p className="flex flex-col">
                <small className="text-small text-light-700">
                  Reynaldo Reyna
                </small>
                <small className="text-small text-light-800">
                  Lead Architect
                </small>
              </p>
            </div>
            <Highlight
              description={
                <div className="flex flex-col gap-2">
                <p className="text-p">
                  Update: We introduced a new US region with
                  cutting-edge hardware, even faster than the AWS instances we
                  previously used for Quickcoach. This upgrade makes Quickcoach
                  twice as fast, now offering a <strong>6x performance boost</strong> for just
                  10% of the cost compared to their previous setup on Meteor Cloud (Galaxy)!
                  </p>
                  <p className="text-p">
                    PubSub Res. Time: 3.02ms / Method Res. Time: 5.72ms
                  </p>
                </div>
              }
            />
            <p className="text-p">
              QuickCoach, a prominent online coaching platform, sought our help
              to improve their Meteor App's hosting. Their previous setup on
              Meteor Cloud (Galaxy) and MongoDB Atlas proved costly. We stepped
              in to find a cost-effective and high-performance solution.
            </p>
            <p className="text-p">
              By migrating the app & databases (MongoDB & Redis) to our
              optimized hosting platform, we achieved a remarkable 10x reduction
              in hosting expenses. Additionally, through expert fine-tuning, we
              boosted the app's performance by an impressive 3x. QuickCoach's
              users now enjoy faster response times, resulting in heightened
              satisfaction and an improved coaching experience. With these
              optimizations, QuickCoach can now focus more on enhancing their
              coaching services while enjoying cost-efficient and reliable
              hosting.
            </p>
          </div>
          <aside className="shrink-0 grow-0 lg:basis-[250px]">
            <div className="rounded-8 flex flex-col items-start gap-2 bg-neutral-500 p-4">
              <img
                src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-quickcoach-logo.png"
                alt="QuickCoach"
                className="mb-2"
              />
              <p className="text-p text-light-500 font-medium">QuickCoach</p>
              <p className="text-p text-light-700">
                Professional coaching software, built by coaches, for coaches.
              </p>
              <p className="text-p text-light-700">
                <span className="text-light-500">32,000+</span> professionals
                <br />
                <span className="text-light-500">150,000+</span> managed clients
                <br />
                <span className="text-light-500">900,000+</span> plans created
              </p>
              <Link
                event="website_csQuickCoach_website_click"
                className="text-p text-lime-600"
                href="https://quickcoach.fit/"
                target="_blank"
              >
                quickcoach.fit
              </Link>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </>
);
