import React from 'react';
import { Link } from '../components/Link';
import { RoutePaths } from '../RoutePaths';
import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';
import { getHrefWithSameQueryParams } from '../utilities';

const faqs = [
  {
    question: `What's the first step?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          A call with our CEO to discuss your needs. We're engineers ready to
          help you directly, not salespeople.
        </p>
      </div>
    ),
  },
  {
    question: `Can I try before committing?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          Yes, you can try our standard pricing regions or we can set up a
          custom environment for you to test.
        </p>
      </div>
    ),
  },
  {
    question: `How quickly can I start?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          We set up regions within 2 days of discussing configurations. Your
          private custom region, including migrated databases and apps, can be
          running 48 hours after first contact.
        </p>
      </div>
    ),
  },
  {
    question: `Which cloud providers do you support?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          We support all cloud providers, big and small. You can run your
          regions anywhere. We'll help you find the best option.
        </p>
      </div>
    ),
  },
  {
    question: `What services do you offer besides apps and databases?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          We handle any workload. Our services adapt to your needs, from custom
          software to complex multi-region setups. We also install self-hosted
          software for you.
        </p>
      </div>
    ),
  },
  {
    question: `Is there a setup fee?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          Usually, yes. We review each case individually. We'll discuss this in
          our first call. No hidden or surprise fees.
        </p>
      </div>
    ),
  },
  {
    question: `What's your SLA?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          We customize our plans to fit your specific needs. Contact us to
          discuss your requirements and we will create a custom SLA for you.
        </p>
      </div>
    ),
  },
  {
    question: `What's your historical uptime?`,
    answer: (
      <div className="flex flex-col gap-2">
        <p>
          Our standard pricing regions have a 99.99% historical uptime. See our{' '}
          <Link
            target="_blank"
            to="https://zcloud.statuspage.io/"
            className="text-lime-500"
          >
            status page
          </Link>{' '}
          for current info.
        </p>
      </div>
    ),
  },
];

export const CustomPage = () => {
  return (
    <>
      <section
        data-id="custom"
        className="bg-hero-cloud mt-16 bg-[center_-120px] bg-no-repeat px-4 lg:px-8"
      >
        <div className="container mx-auto">
          <div className="max-w-medium mx-auto flex flex-col items-center gap-8">
            <h2 className="text-h3 md:text-h2 text-light-100 max-w-[850px] pt-12 text-center">
              <span className="text-neon">Quave Cloud Custom</span>: Top-Tier
              PaaS Features at a Fraction of the Cost
            </h2>
            <h5 className="text-h5 text-light-100 text-center">
              Get all the benefits of premium PaaS providers like Heroku or
              Vercel without the huge price tag. Save at least 50% on your
              current costs!
            </h5>

            <div className="flex flex-col gap-8 pt-8">
              <div>
                <h3 className="text-h4 text-light-100 mb-4">
                  Why Quave Cloud Custom:
                </h3>
                <ul className="text-p mb-6 flex flex-col gap-4">
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        Fixed pricing
                      </strong>
                      <p className="text-light-700">
                        Monthly license fee depending on your resources usage
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        Provider flexibility
                      </strong>
                      <p className="text-light-700">
                        Choose your preferred <strong>cloud provider</strong> or
                        let us find the <strong>best solution</strong> for you
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        Cost optimization
                      </strong>
                      <p className="text-light-700">
                        Use your <strong>existing cloud credits</strong> or{' '}
                        <strong>special deals</strong> with{' '}
                        <strong>direct billing</strong>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        Full DevOps
                      </strong>
                      <p className="text-light-700">
                        We handle <strong>all DevOps tasks</strong>, including{' '}
                        <strong>migration</strong> and{' '}
                        <strong>management</strong>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        Comprehensive support
                      </strong>
                      <p className="text-light-700">
                        <strong>Expert assistance</strong> for both{' '}
                        <strong>code</strong> and <strong>DevOps</strong>{' '}
                        questions
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        Advanced monitoring
                      </strong>
                      <p className="text-light-700">
                        Access to <strong>professional tools</strong> like{' '}
                        <strong>Grafana</strong> and <strong>Prometheus</strong>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3 text-xl text-lime-500">✓</span>
                    <div>
                      <strong className="text-h6 text-light-100">
                        User-friendly interface
                      </strong>
                      <p className="text-light-700">
                        Features include <strong>rollback</strong>,{' '}
                        <strong>config history</strong>, and{' '}
                        <strong>secret encryption</strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="bg-light-900 rounded-lg p-6">
                <h4 className="text-h5 text-light-100 mb-4">
                  Featured Case Study: Paris 2024 Olympics
                </h4>
                <p className="text-p text-light-300 mb-4">
                  Learn how Quave Cloud and ALLOHOUSTON engineered a cloud
                  solution for the Paris 2024 Olympics.
                </p>
                <blockquote className="text-light-300 mb-4 border-l-4 border-lime-500 pl-4 italic">
                  "Their technical support is amazing and the reactivity is
                  incredible. I highly recommend working with them!"
                  <footer className="text-light-500 mt-2 text-sm">
                    - Victor Parpoil, Co-founder of ALLOHOUSTON
                  </footer>
                </blockquote>
                <Link
                  event="website_custom_allohouston_click"
                  to={getHrefWithSameQueryParams(RoutePaths.CS_ALLOHOUSTON)}
                  className="inline-flex items-center text-lime-500 transition-colors duration-200 hover:text-lime-600"
                >
                  Read the Olympic-Ready case study
                  <i className="fas fa-chevron-right ml-2 text-sm"></i>
                </Link>
              </div>
              <div>
                <h3 className="text-h4 text-light-100 mb-4">
                  How Quave Cloud Saves You Money:
                </h3>
                <ul className="pt-2">
                  <li className="mb-8 flex gap-4">
                    <span className="border-light-700 text-light-500 h-[29px] w-[29px] shrink-0 grow-0 rounded-full border-2 text-center">
                      1
                    </span>
                    <div>
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        Choose your cloud provider
                      </h6>
                      <p className="text-p">
                        We help you find the best solution for your needs.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 flex gap-4">
                    <span className="border-light-700 text-light-500 h-[29px] w-[29px] shrink-0 grow-0 rounded-full border-2 text-center">
                      2
                    </span>
                    <div>
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        Direct billing
                      </h6>
                      <p className="text-p">
                        Pay your chosen provider directly, using your special
                        deals or credits.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 flex gap-4">
                    <span className="border-light-700 text-light-500 h-[29px] w-[29px] shrink-0 grow-0 rounded-full border-2 text-center">
                      3
                    </span>
                    <div>
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        Optimized setup
                      </h6>
                      <p className="text-p">
                        We configure everything for maximum efficiency and
                        cost-effectiveness.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 flex gap-4">
                    <span className="border-light-700 text-light-500 h-[29px] w-[29px] shrink-0 grow-0 rounded-full border-2 text-center">
                      4
                    </span>
                    <div>
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        Ongoing optimization
                      </h6>
                      <p className="text-p">
                        If we find better deals in the future, we'll migrate you
                        again at no extra cost.
                      </p>
                    </div>
                  </li>
                  <li className="mb-8 flex gap-4">
                    <span className="border-light-700 text-light-500 h-[29px] w-[29px] shrink-0 grow-0 rounded-full border-2 text-center">
                      5
                    </span>
                    <div>
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        Fixed monthly fee
                      </h6>
                      <p className="text-p">
                        Predictable costs for our platform and expert support.
                      </p>
                    </div>
                  </li>
                </ul>
                <p className="text-p text-light-500 mb-6">
                  If you're spending over $2,000 monthly on PaaS, switching to
                  Quave Cloud is a no-brainer. We'll help you save at least 50%
                  while providing top-tier features and support.
                </p>
              </div>

              <div className="bg-neon rounded-8 w-full max-w-[550px] self-center p-px">
                <div className="rounded-8 flex flex-col items-center gap-6 bg-neutral-500 p-6">
                  <p className="text-p text-light-500 text-center">
                    Ready to slash your cloud costs while getting premium PaaS
                    features? Let's talk about how Quave Cloud Custom can
                    transform your business.
                  </p>
                  <Link
                    to={RoutePaths.BOOK_DEMO}
                    className="btn-primary"
                    onClick={() =>
                      simpleanalyticsSendEvent('website_custom_book_demo_click')
                    }
                  >
                    Book a Demo and Start Saving
                  </Link>
                </div>
              </div>
              <div>
                <h3 className="text-h4 text-light-100 mb-4">
                  Frequently Asked Questions:
                </h3>
                <ul className="grid max-w-[1024px] grid-cols-1 gap-x-6 gap-y-6 pt-2 md:grid-cols-2">
                  {faqs.map(({ question, answer }) => (
                    <li className="">
                      <h6 className="text-h6 font-regular text-light-500 mb-2">
                        {question}
                      </h6>
                      <p className="text-p">{answer}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
