import CaseStudiesSection from '../pages/sections/CaseStudies';
import React from 'react';
import { ListComponent } from '../components/ListComponent';
import { RoutePaths } from '../RoutePaths';
import { Link } from '../components/Link';
import { getHrefWithSameQueryParams } from '../utilities';

export const MeteorPage = () => (
  <>
    <section data-id="meteor" className="mt-16 px-4 lg:px-8">
      <div className="container mx-auto flex flex-col items-center gap-4">
        <h2 className="text-h3 text-light-100 md:text-h2 pb-16 text-center">
          Host Your Meteor Apps on Quave Cloud
        </h2>

        <h6 className="max-w-small text-h6 text-light-100 font-light">
          Imagine your Meteor apps with seamless integration, cost-saving,
          efficiency, and hassle-free operation.
        </h6>
        <h6 className="max-w-small text-h6 text-light-100 font-light">
          Is your current solution delivering these benefits? Are your
          operations streamlined? Is your service cost-effective?
        </h6>

        <h6 className="max-w-small text-h6 text-light-100 font-light">
          Use Quave Cloud for your Meteor apps. We'll always do extra to help
          and will make stuff simpler for you.
        </h6>

        <h4 className="max-w-small text-h4 text-light-100 mt-8 text-center font-light">
          Why Choose Quave Cloud?
        </h4>

        <ListComponent
          items={[
            <>
              <strong>Cost-Effective</strong>: Running Meteor apps on Quave
              Cloud could be up to 10x cheaper than Meteor Cloud (Galaxy) and 3x
              cheaper than AWS. Try us to see the savings or read the{' '}
              <Link to={RoutePaths.CS_QUICKCOACH} className="text-lime-500">
                QuickCoach
              </Link>{' '}
              case study.
            </>,
            <>
              <strong>Meteor Expert Support</strong>: Our support team has
              extensive experience with Meteor. Our CEO, Filipe Névola, is the
              former Meteor CEO with over 10 years of Meteor experience. We can
              assist with any Meteor related questions, including scaling your
              app. Visit our{' '}
              <Link
                href={getHrefWithSameQueryParams(
                  'https://quave.dev/services/meteor'
                )}
                target="_blank"
                className="text-lime-500"
              >
                Meteor
              </Link>{' '}
              page to understand our team's expertise.
            </>,
            <>
              <strong>MongoDB Support</strong>: Run MongoDB in the same cluster
              with Quave Cloud. This not only improves efficiency and
              observability but also simplifies management and reduces costs.
            </>,
            <>
              <strong>Everything together</strong>: If your product has many
              parts using different tech, we can help with all. Read the{' '}
              <Link to={RoutePaths.CS_POLI} className="text-lime-500">
                Poli
              </Link>{' '}
              case study.
            </>,
            <>
              <strong>Save DevOps Time</strong>: We value your time. Hours spent
              on system maintenance could be used for innovation and product
              enhancement. Our platform reduces DevOps time, allowing your team
              to focus on core functionality. Read the{' '}
              <Link to={RoutePaths.CS_EATFRESH} className="text-lime-500">
                Eat Fresh
              </Link>{' '}
              case study.
            </>,
          ]}
        />

        <h4 className="max-w-small text-h4 text-light-100 mt-8 text-center font-light">
          The Clear Choice
        </h4>

        <h6 className="max-w-small text-h6 text-light-100 font-light">
          In the digital world, you need an efficient, supportive, and
          budget-friendly platform. Compared to other options, Quave Cloud is
          the clear choice for running Meteor apps.
        </h6>

        <h6 className="max-w-small text-h6 text-light-100 font-light">
          Switch to Quave Cloud today for a smoother, more efficient, and
          cost-effective Meteor application experience.
        </h6>

        <h6 className="max-w-small text-h6 text-light-100 font-light">
          The Quave Cloud team handles the entire migration for you, including
          data migration. Read the{' '}
          <Link to={RoutePaths.CS_DUNDERON} className="text-lime-500">
            Dunderon
          </Link>{' '}
          case study.
        </h6>

        <h4 className="max-w-small text-h4 text-light-100 mt-8 text-center font-light">
          Act Today
        </h4>

        <h6 className="max-w-small text-h6 text-light-100 font-light">
          <Link
            event="website_home_getStartedHero_click"
            href={getHrefWithSameQueryParams(RoutePaths.BOOK_DEMO)}
            className="text-lime-600"
          >
            Schedule a call
          </Link>{' '}
          and start your migration today! If you prefer, email{' '}
          <a href="mailto:support@zcloud.ws" className="text-lime-600">
            support@zcloud.ws
          </a>
          .
        </h6>
      </div>
    </section>
  </>
);
