export const sendEmail = ({
  fromEmail,
  fromName,
  content,
  page,
  setSuccess,
  setError,
  reset,
}) => {
  const body = JSON.stringify({
    fromEmail,
    fromName: fromName || 'Unknown from website',
    content: content || 'No content from website',
    to: 'support@zcloud.ws',
    from: 'support@quave.com.br',
    subject: `Quave Cloud WebSite - ${fromName} (${fromEmail})${
      page ? ` - ${page}` : ''
    }`,
  });

  // it needs to have www, otherwise cloudfront is blocking it
  fetch('https://www.codeftw.dev/api/sendEmail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
    .then(() => {
      setSuccess('Email sent successfully! Thank you.');
      reset();
    })
    .catch((e) => {
      setError(
        'An unknown error happened. Please send us an email instead. Our email is support@zcloud.ws'
      );
      console.error(`Error sending email`, e);
    });
};
