import { getHrefWithSameQueryParams } from '../utilities';
import { Link } from '../components/Link';
import { RoutePaths } from '../RoutePaths';
import React from 'react';
import SmallFooter from './sections/SmallFooter';
import { ListComponent } from '../components/ListComponent';

const Join = () => (
  <div className="bg-neon rounded-8 w-full max-w-[550px] p-px">
    <div className="rounded-8 flex flex-col items-center gap-6 bg-neutral-500 p-6">
      <p className="text-p text-light-500">
        Interested? Please reach out to us at{' '}
        <Link
          event="website_affiliates_email_click"
          className="text-lime-600"
          href={getHrefWithSameQueryParams('mailto:affiliates@zcloud.ws')}
          target="_blank"
        >
          affiliates@zcloud.ws
        </Link>
        .
      </p>
    </div>
  </div>
);

export const AffiliatesPage = () => {
  return (
    <>
      <section
        data-id="workshop"
        className="bg-hero-cloud mt-16 bg-[center_-120px] bg-no-repeat px-4 lg:px-8"
      >
        <div className="container mx-auto">
          <div className="max-w-medium mx-auto flex flex-col items-center gap-6">
            <h2 className="max-w-small text-h3 text-light-100 md:text-h2 text-center">
              <span className="text-neon">Affiliates</span>
            </h2>
            <h5 className="max-w-small text-h5 text-light-100 text-center">
              At Quave Cloud, one of our key objectives is to keep our team as
              minimal as possible. Rather than investing heavily in building an
              extensive sales team, we decided to create a network of affiliates
              to help drive us business.
            </h5>
            <Join />
            <h6 className="max-w-small text-h6 text-light-100 mt-8 text-center font-light">
              Successful Quave Cloud affiliates publish content showcasing our
              platform, then earn recurring commissions for business that they
              refer to us. If you're interested in becoming an affiliate, you've
              come the the right place!
            </h6>

            <h3 className="max-w-small text-h3 text-light-100 mt-20 text-center font-light">
              Build a sustainable recurring revenue stream
            </h3>
            <h6 className="max-w-small text-h6 text-light-100 font-light">
              Quave Cloud affiliates are key partners in building our business.
              We're proud to offer a commission structure that's far more
              favorable than your average full-time sales rep at a SaaS company
              would have.
            </h6>

            <ListComponent
              items={[
                <>
                  Each affiliate is given a unique link that allows us to track
                  sign-ups that they've referred to us.
                </>,
                <>
                  Affiliates earn a 10% commission on all successfully processed
                  payments—<strong>forever</strong>.
                </>,
                <>
                  Affiliate commissions are based on{' '}
                  <strong>all charges</strong> we do. When the customers you
                  refer to us spend more money, you'll earn more money!
                </>,
              ]}
            />

            <h3 className="text-h3 text-light-100 mt-20 font-light">
              Quave Cloud affiliates play the infinite game
            </h3>
            <div className="mb-20 flex flex-col gap-8 lg:flex-row">
              <p className="text-h6 text-light-500 mb-6 font-light">
                Our affiliate program is structured this way because we're
                optimizing for long term partnerships that consistently refer us
                business and compound in value over time.
              </p>

              <ListComponent
                items={[
                  <>
                    Because commissions are paid forever and include all our
                    revenue, our company, our affiliates, and our customers'
                    incentives are perfectly aligned—we all succeed together!
                  </>,
                  <>
                    We're specifically looking for affiliates that refer high
                    potential customers to us, and care about the success of the
                    customer beyond the initial sale.
                  </>,
                  <>
                    Because we pay commissions indefinitely, you can easily
                    scale up a significant revenue stream by referring new
                    customers.
                  </>,
                  <>
                    Businesses utilizing Quave Cloud primarily operate on our
                    platform, resulting in exceptionally low customer churn. As
                    of January 2024, we have seen zero churn from customers who
                    establish successful businesses!
                  </>,
                  <>
                    As a Quave Cloud affiliate, you'll receive personalized
                    sales training and full access to our team. We will
                    collaborate directly with you to identify the type of
                    content you're best suited to create for effectively
                    generating new business.
                  </>,
                ]}
              />
            </div>
            <Join />
            <div className="max-w-small text-center">
              <p className="text-p text-light-700 mb-6">
                Quave Cloud is a hosting platform capable of running any modern
                application architecture and databases.
              </p>
              <Link
                event="website_workshop_learnMore_click"
                href={getHrefWithSameQueryParams(RoutePaths.HOME)}
                className="btn-outline"
              >
                Learn more <i className="fa fa-arrow-right" />
              </Link>
            </div>

            <SmallFooter />
          </div>
        </div>
      </section>
    </>
  );
};
