export const getHrefWithSameQueryParams = (url) => {
  const currentParams = new URLSearchParams(window.location.search);
  const newUrl = new URL(url, window.location.origin);
  const newParams = newUrl.searchParams;

  for (const [key, value] of currentParams) {
    if (!newParams.has(key)) {
      newParams.append(key, value);
    }
  }

  return newUrl.toString();
};

export const createEnum = (obj, options = {}) =>
  Object.entries(obj).reduce(
    (acc, [key, value], index) => ({
      ...acc,
      [key]: {
        ...options.defaultFields,
        name: key,
        index,
        ...value,
      },
    }),
    {}
  );
