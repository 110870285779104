import { getHrefWithSameQueryParams } from '../utilities';
import { Link } from '../components/Link';
import { RoutePaths } from '../RoutePaths';
import React, { useState } from 'react';
import { Button } from '../components/Button';
import { subscribeToLemeno } from '../infra/lemenoServices';
import { simpleanalyticsSendEvent } from '../simpleAnalyticsSendEvent';
import SmallFooter from './sections/SmallFooter';
import { sendEmail } from '../components/sendEmail';

export const RegionInBrazilPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const signUp = () => {
    setError('');
    setIsSignedUp(false);
    setSending(true);

    // check if the email is informed, otherwise set error
    if (!email) {
      setError('Email is required');
      setSending(false);
      return;
    }

    sendEmail({
      fromEmail: email,
      fromName: email,
      content: 'region_br',
      page: RoutePaths.REGION_IN_BRAZIL,
      setSuccess: () => {
        setIsSignedUp(true);
        setSending(false);
      },
      setError: (e) => {
        setError(e);
        setSending(false);
      },
      reset: () => {
        setEmail('');
        setError('');
      },
    });
  };
  return (
    <>
      <section
        data-id="region-br"
        className="bg-hero-cloud mt-16 bg-[center_-120px] bg-no-repeat px-4 lg:px-8"
      >
        <div className="container mx-auto">
          <div className="max-w-medium mx-auto flex flex-col items-center gap-8">
            <h2 className="text-h3 md:text-h2 text-light-100 max-w-[850px] pt-12 text-center">
              <span className="text-neon">
                Nova Região Quave Cloud no Brasil
              </span>
              : Hospedagem de alta qualidade a preços competitivos
            </h2>
            <h5 className="text-h5 text-light-100 text-center">
              Lançamento de uma nova região do Quave Cloud no Brasil com preços
              altamente competitivos!
            </h5>

            <div className="bg-neon rounded-8 w-full max-w-[550px] p-px">
              {!isSignedUp && (
                <div className="rounded-8 flex flex-col items-center gap-6 bg-neutral-500 p-6 sm:flex-row">
                  <input
                    name="name"
                    type="text"
                    placeholder="Informe seu email para saber mais"
                    className="rounded-4 text-p text-light-500 placeholder:text-light-700 w-[300px] bg-neutral-400 px-4 py-3 sm:w-full"
                    value={email}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') {
                        return;
                      }

                      signUp();
                      simpleanalyticsSendEvent(
                        'website_region_br_subscribe_enter'
                      );
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    className="btn-primary shrink-0 grow-0"
                    event="website_region_br_subscribe_click"
                    onClick={signUp}
                    disabled={sending}
                  >
                    {sending ? 'Enviando...' : 'Saiba mais'}
                  </Button>
                </div>
              )}
              {isSignedUp && (
                <div className="rounded-8 flex flex-col items-center gap-6 bg-neutral-500 p-6">
                  <p className="text-p text-light-500">
                    Obrigado pelo seu interesse! Entraremos em contato em breve
                    com mais informações sobre nossa nova região no Brasil.
                  </p>
                </div>
              )}
            </div>
            {error && (
              <div className="rounded-4 bg-danger-700A10 border-danger-600 text-danger-200 text-p border px-4 py-2">
                {error}
              </div>
            )}

            <div className="flex flex-col gap-8 pt-8 lg:flex-row">
              <div>
                <p className="text-p text-light-500 mb-6">
                  Atenção empreendedores e desenvolvedores brasileiros! Você
                  gostaria de hospedar seu app ou banco de dados no Brasil, mas
                  os custos elevados estão te impedindo?
                </p>

                <p className="text-p text-light-500 mb-6">
                  Temos uma novidade empolgante para compartilhar: Estamos
                  lançando uma nova região do Quave Cloud no Brasil com preços
                  altamente competitivos, em um provedor de cloud de renome
                  global.
                </p>
              </div>
              <div>
                <p className="text-p text-light-500 mb-6">Oferecemos:</p>
                <ul className="text-p text-light-500 mb-6 list-inside list-disc">
                  <li>Custos excelentes (pelo menos 50% mais baixo que AWS)</li>
                  <li>Migração assistida (fazemos tudo se você quiser)</li>
                  <li>
                    Infraestrutura confiável (veja nossos depoimentos ou fale
                    com nossos clientes)
                  </li>
                </ul>
                <p className="text-p text-light-500">
                  Até agora, nossa base de clientes era quase 100%
                  internacional. Agora, estamos empolgados em trazer o Quave
                  Cloud para o mercado brasileiro!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
