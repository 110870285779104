import { Link } from '../../components/Link';
import { getHrefWithSameQueryParams } from '../../utilities';
import { RoutePaths } from '../../RoutePaths';

export const CSAlloHoustonPage = () => (
  <>
    <section className="mt-8 px-4 lg:mt-16 lg:px-8">
      <div className="container mx-auto">
        <div className="max-w-medium mx-auto flex flex-col gap-8 lg:flex-row">
          <div className="shrink-0 grow-0 pt-2 lg:basis-[150px]">
            <Link
              event="website_csAlloHouston_back_click"
              to={getHrefWithSameQueryParams(RoutePaths.CASE_STUDIES)}
              className="text-p text-lime-600"
            >
              <i className="fa fa-arrow-left"></i> <span>All case studies</span>
            </Link>
          </div>
          <div className="flex flex-col gap-6">
            <h4 className="text-h4 text-light-500">
              Olympic-Ready: Custom Cloud Solution for Paris 2024
            </h4>
            <h6 className="text-h6 text-light-500 font-light">
              "Filipe Névola and his team have helped us a lot on this matter.
              Their technical support is amazing and the reactivity is
              incredible (especially given the time zone difference). I highly
              recommend working with them!"
            </h6>
            <div className="flex items-center gap-2">
              <div className="w-12">
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-allohouston-photo.webp"
                  alt="ALLOHOUSTON Representative"
                  className="rounded-full"
                />
              </div>
              <p className="flex flex-col">
                <small className="text-small text-light-700">
                  Victor Parpoil
                </small>
                <small className="text-small text-light-800">Co-founder</small>
              </p>
            </div>
            <p className="text-p">
              ALLOHOUSTON and zCloud joined forces to create a special cloud
              setup for the Paris 2024 Olympics. Their goal was to ensure smooth
              operations for companies operating in Paris, France, during the
              event.
            </p>

            <p className="text-p">
              zCloud built a custom cloud region in Paris using OVH Cloud. This
              setup made the apps run fast and kept data secure within France,
              which was ideal for this major event.
            </p>

            <p className="text-p">
              ALLOHOUSTON developed two main tools: "joptimiz.green" for vehicle
              and activity registration and control and "Itineriz" for traffic
              rules and for computing the best available route given the event
              constraints. These apps needed to handle users in real-time.
            </p>

            <p className="text-p">
              zCloud handled the easy deployment process, offering the same
              experience for all regions, both public and private. This let the
              ALLOHOUSTON team focus fully on the apps. Meanwhile, zCloud took
              care of all DevOps tasks, including setting up custom machines for
              the apps.
            </p>

            <p className="text-p">
              zCloud seamlessly enabled high availability and horizontal scaling
              for these tools. As a result, 45,800 vehicles were registered and
              validated by Joptimiz, and the tools received more than 150,000
              visits.
            </p>
          </div>
          <aside className="shrink-0 grow-0 lg:basis-[250px]">
            <div className="rounded-8 flex flex-col items-start gap-2 bg-neutral-500 p-4">
              <div className="w-24">
                <img
                  src="https://zcloud-static-assets.s3.amazonaws.com/zcloud-images/cs-allohouston-logo.png"
                  alt="ALLOHOUSTON"
                  className="mb-2"
                />
              </div>
              <p className="text-p text-light-500 font-medium">ALLOHOUSTON</p>
              <p className="text-p text-light-700">
                supports leaders and business teams in achieving their goals
                with an end-to-end and innovative approach to digital
                transformation
              </p>
              <p className="text-p text-light-700">
                <span className="text-light-500">20+</span> apps in production
                <br />
                <span className="text-light-500">25k+</span> users
              </p>
              <Link
                event="website_csAlloHouston_website_click"
                className="text-p text-lime-600"
                href="https://www.allohouston.fr/"
                target="_blank"
              >
                allohouston.fr
              </Link>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </>
);
